import * as React from 'react';

import { CssBaseline } from '@mui/material';
import type { WebAppConfig } from '@progyconnect/webapp-types';
import Box from '@mui/material/Box';
import ConfirmDialog from '../components/ConfirmDialog';
import { LanguageSwitcherButton } from '../components/LanguageSwitcher';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import WizardConfigure from '../components/configWizard/WizardConfigure';
import WizardConnect from '../components/configWizard/WizardConnect';
import WizardSelect from '../components/configWizard/WizardSelect';
import { useTranslation } from 'react-i18next';
import { State } from '../redux/state';
import { useSelector } from 'react-redux';
import { IntegrationContextType, useIntegration } from '../contexts/IntegrationProvider';
import { IntegrationsCombo } from '../components/IntegrationCombo';
import WizardPolicies from '../components/configWizard/WizardPolicies';
import WizardWelcome from '../components/configWizard/WizardWelcome';
import { useLocalStorage } from 'usehooks-ts';

const steps = ['Welcome', 'Policies', 'Selection', 'Connection', 'Activation'];

export default function Wizard() {
    const { t } = useTranslation();
    const webappConfig = useSelector<State, WebAppConfig | undefined>((state) => state.config);
    const { integrations } = useIntegration() as IntegrationContextType;

    const isConnected = useSelector<State, boolean | undefined>((state) => state.isConnected);
    const [welcomeCompleted, setWelcomeCompleted] = useLocalStorage<string>('progyconnect-welcomeCompleted', 'false');
    const handleWelcomeCompleted = () => setWelcomeCompleted('true');
    const [policiesAccepted, setPoliciesAccepted] = useLocalStorage<string>('progyconnect-policiesAccepted', 'false');
    const handlePoliciesAccepted = () => setPoliciesAccepted('true');

    function getStepContent(step: number) {
        switch (step) {
            case 0:
                return <WizardWelcome handleContinueClick={handleWelcomeCompleted} />;
            case 1:
                return <WizardPolicies handleContinueClick={handlePoliciesAccepted} />;
            case 2:
                return <WizardSelect />;
            case 3:
                return <WizardConnect />;
            case 4:
                return <WizardConfigure />;
        }
    }

    let activeStep;
    if (welcomeCompleted !== 'true') activeStep = 0;
    else if (policiesAccepted !== 'true') activeStep = 1;
    else if (!webappConfig?.type) activeStep = 2;
    else if (!isConnected) activeStep = 3;
    else activeStep = 4;

    if (!webappConfig?.orderWorkflowConfigs?.status || webappConfig.orderWorkflowConfigs.status === 'initial') {
        return (
            <Box sx={{ display: 'flex', margin: '20px' }}>
                <CssBaseline />
                <ConfirmDialog />
                <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps: { completed?: boolean } = {};
                            const labelProps: {
                                optional?: React.ReactNode;
                            } = {};
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{t(label)}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <React.Fragment>
                        <div style={{ height: '80vh', width: '100%' }}>{getStepContent(activeStep)}</div>
                    </React.Fragment>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                        <LanguageSwitcherButton />
                        {(integrations?.length ?? 0) > 1 && (
                            <div style={{ width: '200px' }}>
                                <IntegrationsCombo />
                            </div>
                        )}
                    </div>
                </Box>
            </Box>
        );
    }

    return <>Something went wrong, please try to refresh your page</>;
}
