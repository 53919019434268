import { CustomerType, Mapping, WebappEntryType } from '@progyconnect/webapp-types';

export const entryTypes: { [key in CustomerType]: WebappEntryType[] } = {
    PivoHub: [
        {
            type: 'invoicing',
            class: 'ORDER',
            label: 'Orders',
            icon: 'Description',
            extraColumns: [
                {
                    id: 'routeName',
                    label: 'Route',
                    type: 'string',
                    groupable: true,
                },
                {
                    id: 'deliveryDate',
                    label: 'Delivery Date',
                    type: 'date',
                    groupable: false,
                },
            ],
        },
        {
            type: 'mapping',
            class: 'PRODUCT',
            valueClass: 'ITEM',
            label: 'Products',
            icon: 'SportsBar',
        },
        {
            type: 'mapping',
            class: 'CUSTOMER',
            valueClass: 'CUSTOMER',
            label: 'Customers',
            icon: 'EmojiPeople',
            extraColumns: [
                {
                    id: 'address',
                    label: 'Address',
                    type: 'string',
                    groupable: false,
                    getValue: (c: Mapping) => c.street + ' ' + c.city,
                },
            ],
        },
        {
            type: 'mapping',
            class: 'TAX',
            valueClass: 'TAX',
            label: 'Taxes',
            icon: 'Calculate',
        },
        {
            type: 'mapping',
            class: 'DEPOSIT',
            valueClass: 'ITEM',
            label: 'Deposits',
            icon: 'Liquor',
        },
        {
            type: 'mapping',
            class: 'TERM',
            valueClass: 'TERM',
            label: 'Terms',
            icon: 'AccessTime',
        },
        {
            type: 'mapping',
            class: 'CHARGE',
            valueClass: 'ITEM',
            label: 'Charges',
            icon: 'AttachMoney',
        },
        {
            type: 'mapping',
            class: 'DISCOUNT',
            valueClass: 'ITEM',
            label: 'Discounts',
            icon: 'Discount',
        },
    ],
    Restock: [
        {
            type: 'invoicing',
            class: 'ORDER',
            label: 'Orders',
            icon: 'Receipt',
            extraColumns: [
                {
                    id: 'entityType',
                    label: 'Type',
                    type: 'string',
                    groupable: false,
                },
            ],
        },
        {
            type: 'invoicing',
            class: 'PURCHASE',
            label: 'Purchases',
            icon: 'Receipt',
            extraColumns: [
                {
                    id: 'entityType',
                    label: 'Type',
                    type: 'string',
                    groupable: false,
                },
            ],
        },
        {
            type: 'mapping',
            class: 'CUSTOMER',
            valueClass: 'CUSTOMER',
            label: 'Customers',
            icon: 'EmojiPeople',
            extraColumns: [
                {
                    id: 'type',
                    label: 'Type',
                    type: 'string',
                    groupable: false,
                },
            ],
        },
        {
            type: 'mapping',
            class: 'VENDOR',
            valueClass: 'VENDOR',
            label: 'Suppliers',
            icon: 'Factory',
        },
        {
            type: 'mapping',
            class: 'PRODUCT',
            valueClass: 'ITEM',
            label: 'Items',
            icon: 'LocalPizza',
        },
        {
            type: 'mapping',
            class: 'TAX',
            valueClass: 'TAX',
            label: 'Taxes',
            icon: 'Calculate',
        },
        {
            type: 'mapping',
            class: 'APACCOUNT',
            valueClass: 'ACCOUNT',
            label: 'Account payable',
            icon: 'Calculate',
        },
    ],
};
